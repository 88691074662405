import { SearchOutlined } from '@ant-design/icons';
import { Avatar, Input, List, Progress, Tag, Popover } from 'antd';
import React from "react";
import Autosuggest from "react-autosuggest";
import { Link } from "react-router-dom";
import SearchWines from "./commandBar/SearchWine";
import firebase from "firebase";

const languages = [
];

const recs = [
  {
    title: "Recommended Articles",
    languages: [
      {
        title: "Create a list of target accounts",
        description: "Revolutionize your sales workflow by creating a target list of accounts using Somm.ai",
        image: null,
        link: "http://help.somm.ai/en/articles/5839692-create-a-list-of-target-accounts-advanced-version",
      },
      {
        title: "Displace Competitor Placements",
        description: "Learn how to discover and export a list of sales opportunities",
        image: null,
        link: "http://help.somm.ai/en/articles/5834844-displace-competitor-placements",
      },
      {
        title: "Evaluate Wine Lists",
        description: "Evaluate Wine Lists",
        image: null,
        link: "http://help.somm.ai/en/articles/5841582-evaluate-wine-lists",
      },
                  {
        title: "Request to Add an Account",
        description: "Restaurant not in our database? Let us know here.",
        image: null,
        link: "https://docs.google.com/forms/d/e/1FAIpQLScik-khCidxPxP3gka8gycFDh9WdCqI4E0K5RIkDuocvnAkuw/viewform?usp=sf_link",
      },
    ]
  }
]

const help = [
  {
    title: "Getting Started",
    languages: [

      {
        title: "How To Set Location Filters",
        description: "Maximize your ability to find the data you need based state, city, and region",
        image: null,
        link: "http://help.somm.ai/en/articles/5826426-how-to-set-location-filters",
      },
      {
        title: "How To Use The Market Analysis Tool",
        description: "Gain the ability to leverage real-time market insights",
        image: null,
        link: "http://help.somm.ai/en/articles/5850045-how-to-use-the-market-analysis-tool",
      },
      {
        title: "How To Use The Notifications Page",
        description: "Use notifications to stay updated on changes for the brands you care about",
        image: null,
        link: "http://help.somm.ai/en/articles/5834685-how-to-use-the-notifications-page",
      }
    ]
  }
]


function getSuggestionValue(suggestion) {
  return suggestion.title;
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.title}</span>;
}

function renderSectionTitle(section) {
  return <strong>{section.title}</strong>;
}

function getSectionSuggestions(section) {
  return section.languages;
}


class App extends React.Component {
  constructor() {
    super();

    this.state = {
      searchHistory: [{
        title: "Recent Searches",
        languages: []
      }],
      value: "",
      groups: [],
      wines: [],
      restaurants: [],
      wines: [],
      aggs: null,
      highlighted: null,
      suggestions: []
    };
  }


  componentDidMount() {

    if (!!firebase.auth().currentUser) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}/searchHistory`)
        .once("value")
        .then(snapshot => {
          if (snapshot.val() && !!Object.values(snapshot.val()) && Object.values(snapshot.val()).length > 0) {

            this.setState({ searchHistory: [
              {
                title: "Recent Searches",
                languages: Object.values(snapshot.val()).slice().reverse().slice(0,3).map(x =>
                ({
                  title: x.name,
                  description: "",
                  image: x.image,
                  link: null
                })
                )
              }
            ]
               })
          }
        });
    }
  }

  saveHistory = (value, image) => {
    if (!!firebase.auth().currentUser) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}/searchHistory`)
        .push(
          {
            name: value,
            image: image,
          }
        )
        .catch(err => console.log(err))
    }
  }

  addRestaurant = (x) => {
    if (!!firebase.auth().currentUser && !!this.props.Distributor) {
      firebase
        .database()
        .ref(`d/${this.props.Distributor}/Restaurants`)
        .push(x)
        .catch(err => console.log(err))
      alert("Saved")
    }
  }

  onChange = (event, { newValue, method }) => {
    //console.log(method)
    if (method === "type") {
      this.setState({
        value: newValue
      });
    }

  };

  onSuggestionsFetchRequested = ({ value }) => {
    //console.log(getSuggestions(value));
    //this.setState({
    //  suggestions: getSuggestions(value)
    //});
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [
        {
          title: "2050s",
          languages: [
            {
              title: "C",
              year: 2055
            }
          ]
        }
      ]
    });
  };

  onPressEnter = () => false&&this.props.history.push("/s/" + this.state.value.replace("'", ""));

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    console.log(event, suggestion, suggestionValue, suggestionIndex, sectionIndex, method)
    if (suggestion.link) {
      if (this.state.value) {
        this.saveHistory(this.state.value, suggestion.image)
      }
      if (suggestion.link.includes('http')) {
        window.open(suggestion.link)
      } else {
        this.props.history.push(suggestion.link)
      }
    }
    else {
      this.setState({ value: suggestion.title })
    }

  }



  moldSearcHistory = (l) => [
    {
      title: "Recent Searches",
      languages: l.map(x =>
      ({
        title: x.name,
        description: "",
        image: null,
        link: null
      })
      )
    }
  ]

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Find Anything...",
      value: value,
      onChange: this.onChange,
      ref: this.props.myRef
    };


    return (
      <>
        <span class="hidden">
          {this.state.value.length > 2 && [
          <SearchWines search={this.state.value} 
          updateGroups={x => this.setState({ groups: x })} 
          update={x => this.setState({ wines: x })} 
          updateAggs={x => this.setState({ aggs: x })} 
          updateRests = {x=> this.setState({restaurants:x})}
          {...this.props} 
          />
        ]
          }
        </span>
        <Autosuggest
          multiSection={true}
          suggestions={
            [
              {
                title: "Full Text Wine Search",
                languages: this.state.value.length>2?[
                  {
                    title: this.state.value,
                    description: "Search for any wine that matches "+this.state.value,
                    image: null,
                    link: "/s/"+this.state.value,
                  }
                ]:[]
              }
            ]
            .concat(this.state.wines)
            .concat(this.state.restaurants)
            .concat(this.state.groups)
            .concat(this.state.searchHistory)
            .concat(recs)
            .concat(help)
            .filter((section) => section.languages.length > 0)
          }
          highlightFirstSuggestion
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestion={suggestion => <List.Item

            actions={[
              this.props.path=="p"&&suggestion.producer?<Link onClick={e=>e.preventDefault()||e.stopPropagation()||this.props.history.push(`/p/${this.props.path2},${suggestion.producer}` + window.location.href.split(window.location.pathname)[1])}>Add to Current Search</Link>
              :this.props.path=="r"&&suggestion.restaurant?<Link onClick={e=>e.preventDefault()||e.stopPropagation()||this.props.history.push(`/r/${this.props.path2},${suggestion.restaurant}`  + window.location.href.split(window.location.pathname)[1])}>Add to Current Search</Link>
              :this.props.path=="w"&&suggestion.wine?<Link onClick={e=>e.preventDefault()||e.stopPropagation()||this.props.history.push(`/w/${this.props.path2},${suggestion.wine}`  + window.location.href.split(window.location.pathname)[1])}>Add to Current Search</Link>:null,
            ,
            process.env.REACT_APP_DRINKTYPE == "foodNew" && suggestion.restaurant && <Link onClick={e=>e.preventDefault()||e.stopPropagation()||this.addRestaurant(suggestion)}>Save</Link>
          ]}
          
          
          >
            <List.Item.Meta
              avatar={suggestion.image?<Avatar src={suggestion.image} />:<Avatar>{suggestion.title.length>0&&suggestion.title[0]}</Avatar>}
              title={suggestion.title}
              description={suggestion.description}
            />
            {suggestion.variety && <Link onClick={e=>e.preventDefault()||e.stopPropagation()||this.props.history.push(`/s?grape-component=%5B%22${suggestion.variety}%22%5D`)}><Tag style={{ cursor: "pointer" }} color="red">{suggestion.variety}</Tag></Link>}
            {suggestion.regions && suggestion.regions.map(x=><Link onClick={e=>e.preventDefault()||e.stopPropagation()||this.props.history.push(`/s?region-component=%5B%22${x}%22%5D`)}><Tag style={{ cursor: "pointer" }} color="magenta">{x}</Tag></Link>)}
            {suggestion.popularity && <Progress percent={suggestion.popularity / 10} showInfo={false} steps={5} size="small" strokeColor="#52c41a" />}
          </List.Item>
          }


          renderSuggestionsContainer={({ containerProps, children, query }) => <div {...containerProps}>


            <br />
            {this.state.aggs && [
              this.state.aggs.regions.buckets.map(x => <Link to={`/s?region-component=%5B%22${x.key}%22%5D`}><Tag style={{ cursor: "pointer" }} color="magenta">{x.key}</Tag></Link>),
              this.state.aggs.varietals.buckets.map(x => <Link to={`/s?grape-component=%5B%22${x.key}%22%5D`}> <Tag style={{ cursor: "pointer" }} color="red">{x.key}</Tag></Link>,
              <br />,
              <br />,
              <br />
  
              )]}



            <List 
              size="small"
              itemLayout={this.props.isMobile ? "vertical" : "horizontal"}
              >
              {children}
            </List>



          </div>}
          alwaysRenderSuggestions
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          inputProps={inputProps}

          onSuggestionHighlighted={suggestion => {
            console.log(suggestion.suggestion)
            //this.setState({ highlighted: suggestion.suggestion })

          }}

          renderInputComponent={inputProps => <Input
            prefix={<SearchOutlined />}
            size="large"
            placeholder="Find Anything"
            ref={this.props.myRef}
            allowClear
            style={{ zoom: 1.5}}
            onPressEnter={this.onPressEnter}
            {...inputProps}
          />}
        />
      </>
    );
  }
}
export default App;

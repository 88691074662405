import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Input } from 'antd';
import firebase from "firebase";
import React, { Component } from 'react';

const { TextArea } = Input;
const { Search } = Input;

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Team: [],
            reps: [],
        };
    }

    componentDidMount() {
        firebase.database()
            .ref(this.props.firedbRef+"/reps").once("value")
            .then(snapshot => {
                this.setState({
                    reps: snapshot.val() ? snapshot.val() : []
                })
            })
    }

    render() {

        return (
            <div>
                <Autocomplete
                    id="combo-box-demo"
                    options={this.props.DistributorTeam}
                    getOptionLabel={(option) => option.label}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Assign to:" variant="outlined" />}
                    multiple
                    value={this.state.reps}
                    onChange={(event, newValue) => {
                        this.setState({ reps: newValue })
                        if (this.props.firedbRef && this.props.firedbRef.length){
                        firebase.database()
                            .ref(this.props.firedbRef+"/reps")
                            .set(newValue)}
                    }}
                />
            </div>
        );
    }
}

export default Account;
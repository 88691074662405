import React, { Component } from "react";
import { ArrowRightOutlined, CalendarOutlined, DeleteOutlined, EditOutlined, MailFilled, MailOutlined } from '@ant-design/icons';
import { Avatar, Card as AntCard, Popover, Radio, Divider } from 'antd';
import firebase from "firebase";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Team from "../main/components/CRM/Team";
import SearchTermsBadges from "./SearchTermsBadges";


const { Meta } = AntCard;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      distributorItems: [],
      timestamp: {},
      isPro: firebase.auth().currentUser && this.props.isPro
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.isPro !== prevProps.isPro) {
      this.setState({ isPro: firebase.auth().currentUser && this.props.isPro });
    }
  }
  componentDidMount() {
    const handle = firebase.auth().currentUser.uid;
    const itemsRef = firebase.database().ref(`/users/${handle}`);
    itemsRef.on("value", snapshot => {
      let data = snapshot.val();
      let items = snapshot.val().newSavedSearches;
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: item,
          freq: "",
          timestamp: items[item].timestamp
        });
        ////console.log(items[item].search);
      }
      this.setState({
        items: snapshot.val().newSavedSearches ? Object.entries(snapshot.val().newSavedSearches).map(([key, values]) => ({ key: key, ...values })) : [],
        email: data.email,
        restaurant: null
      })
    });

    this.setSavedSearches()

    const itemsRef2 = firebase.database().ref(`/users/${handle}`);
    itemsRef2.on("value", snapshot => {
      let data2 = snapshot.val();
      let items2 = snapshot.val().savedWines;
      let ids = [];
      let obj2 = {};
      for (let item2 in items2) {
        ids.push(items2[item2].reference);
        obj2[items2[item2].reference] = {
          timestamp: items2[item2].timestamp,
          id: item2
        };
      }
      this.setState({ timestamp: obj2 });
      fetch(
        `https://us-central1-coral-field-215519.cloudfunctions.net/function-2?_id=${ids}`,
        {
          method: "POST", // or 'PUT'
          body: JSON.stringify({}), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(response => {
          this.setState({ restaurant: response });
          ////console.log(this.state.restaurant);
        });
    });
  }

  setSavedSearches = () => {
    if (this.props.Distributor) {
      firebase.database()
        .ref(`/d/${this.props.Distributor}`)
        .on("value", snapshot => {
          let items = snapshot.val().newSavedSearches;
          let newState = [];
          console.log(snapshot.val())
          for (let item in items) {
            newState.push({
              id: item,
              title: item,
              freq: "",
              timestamp: items[item].timestamp
            });
            ////console.log(items[item].search);
          }

          if (snapshot.val().newSavedSearches) {
            this.setState({
              distributorItems: Object.entries(snapshot.val().newSavedSearches).map(([key, values]) => ({ key: key, ...values })),
            });
          } else {
            this.setState({
              distributorItems: []
            })
          }
        }
        );
    }
  }

  removeItem = itemId => {
    if (window.confirm('Confirm Deletion')) {
      const handle = firebase.auth().currentUser.uid;
      const itemRef = firebase
        .database()
        .ref(`/users/${handle}/newSavedSearches/${itemId}`);
      itemRef.remove();
    }
  };
  removeSharedItem = itemId => {
    if (window.confirm('Confirm Deletion')) {
      const itemRef = firebase
        .database()
        .ref(`/d/${this.props.Distributor}/newSavedSearches/${itemId}`)
      itemRef.remove()
    };
  };

  updateWine = (itemId, field) => {
    const handle = firebase.auth().currentUser.uid;
    const itemRef = firebase
      .database()
      .ref(`/users/${handle}/savedWines/${itemId}`);
    var newObj = {};
    newObj[field] = true;
    itemRef.update(newObj);
  };

  removeWine = itemId => {
    const handle = firebase.auth().currentUser.uid;
    const itemRef = firebase
      .database()
      .ref(`/users/${handle}/savedWines/${itemId}`);
    itemRef.remove();
  };

  onChangeSubscription = (e, s) => {
    this.props.searchUpdate.setFreq(s.key, e.target.value)
    if (e.target.value == "Subscribe" && !s.name) {
      this.props.searchUpdate.nameSavedSearch(s.key, prompt("Name this search"))
    }
  }

  render() {
    const handle = firebase.auth().currentUser.uid;
    const { items } = this.state;
    return (
      <Container fluid >
        <Row>
          <Col md={{ offset: 0, span: 12 }}>

            {items.filter(x=>!!x.path && !!x.key).length > 0 && (

                  <div className="">
                    <Divider orientation="left">Saved Searches</Divider>
                    <Row>
                      {items.filter(x=>!!x.path && !!x.key).slice(0).reverse().map(item => {
                        return (true ? <ErrorBoundary><Col sm={12} md={6} lg={4} xl={3}><AntCard

                          style={{ margin: "10px" }}
                          actions={[
                            <Popover content={item.timestamp && new Date(item.timestamp).toString().substring(0, 10)}>
                              <CalendarOutlined />
                            </Popover>,
                            <Popover content={<Radio.Group onChange={(e) => this.onChangeSubscription(e, item)} value={item.freq}>
                              <Radio value={"Subscribe"}>Subscribed</Radio>
                              <Radio value={"Unsubscribe"}>Unsubscribed</Radio>
                            </Radio.Group>} title="Email Notifications">
                              {item.freq == "Subscribe" ? <MailFilled style={{ color: "#145aff" }} key="setting" />:
                              <MailOutlined key="setting" />}
                            </Popover>,
                            <Popover content={item.name ? 'Rename' : "Name"} onClick={() => this.props.searchUpdate.nameSavedSearch(item.key, prompt("Name this search"))}>
                              <EditOutlined key="edit" />
                            </Popover>,
                            <Popover content={'Delete'}>
                              <DeleteOutlined key="ellipsis" onClick={() => this.removeItem(item.key)} />
                            </Popover>,
                            <Popover content={'See Results'}>
                              <Link to={`${item.path}?saved=${item.key}`} >
                                <ArrowRightOutlined />
                              </Link>
                            </Popover>,
                          ]}

                        //cover={<img src="https://www.wine.com/product/images/w_767,c_fit,q_auto:good,fl_progressive/wwvj5ei8vfcf4h0mz3cf.jpg"/>}

                        > <Link to={`${item.path}?saved=${item.key}`} >
                            <Meta
                              avatar={<Avatar>{item.path ? item.path[1] == "s" ? "🔎" : item.path[1] == "p" ? "👩‍🌾" : item.path[1] == "w" ? "🍷" : item.path[1] == "r" ? "📍" : item.path[1] : null}</Avatar>}
                              //avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                              title={<h3>{item.name ? item.name : ""}</h3>}
                              description={<SearchTermsBadges {...this.props} item={item} />}

                            /></Link>
                        </AntCard></Col></ErrorBoundary> : null
                        );
                      })}

                    </Row>
                  </div>

            )}
            {this.state.distributorItems.length > 0 && (

                  <div className="">
                    <Divider orientation="left">Saved Shared Searches</Divider>

                    <Row>
                      {this.state.distributorItems.filter(x=>!!x.path && !!x.key).slice(0).reverse().map(item => {
                        return (
                          true ? <Col  sm={12} md={6} lg={4} xl={3}><AntCard
                            style={{ margin: "10px" }}
                            actions={[
                              <Popover content={item.timestamp && new Date(item.timestamp).toString().substring(0, 10)}>
                                <CalendarOutlined />
                              </Popover>,
                              <Popover content={<Team {...this.props} firedbRef={`/d/${this.props.Distributor}/newSavedSearches/${item.key}`} />}
                                title="Email Notifications">
                                <MailOutlined style={{ color: item.freq == "Subscribe" ? "#145aff" : null }} key="setting" />
                              </Popover>,
                              <Popover content={item.name ? 'Rename' : "Name"} onClick={() => this.props.searchUpdate.nameSavedSharedSearch(item.key, this.props.Distributor, prompt("Name this search"))}>
                                <EditOutlined key="edit" />
                              </Popover>,
                              <Popover content={'Delete'}>
                                <DeleteOutlined key="ellipsis" onClick={() => this.removeSharedItem(item.key)} />
                              </Popover>,
                              <Popover content={'See Results'}>
                                <Link to={`${item.path}?sharedSaved=${item.key}`} >
                                  <ArrowRightOutlined />
                                </Link>
                              </Popover>,
                            ]}
                          >
                            <Link to={`${item.path}?sharedSaved=${item.key}`} >
                              <Meta
                                avatar={<Avatar>{item.path[1] == "s" ? "🔎" : item.path[1] == "p" ? "👩‍🌾" : item.path[1] == "w" ? "🍷" : item.path[1] == "r" ? "📍" : item.path[1]}</Avatar>}
                                title={<h3>{item.name ? item.name : ""}</h3>}
                                description={<SearchTermsBadges {...this.props} item={item} />}
                              />
                            </Link>
                          </AntCard></Col> : <tr key={item.key}>

                            <th>
                              {item.timestamp && new Date(item.timestamp).toString().substring(0, 10)}
                            </th>
                            <th>
                              {item.name ? item.name : ""}
                            </th>
                            <th>

                              {item.freq}

                            </th>

                            <th>
                              <Link to={`${item.path}?sharedSaved=${item.key}`} >

                                <SearchTermsBadges {...this.props} item={item} />
                              </Link>
                            </th>

                            <th>
                              <button onClick={() => this.removeSharedItem(item.key)}>
                                Delete
                              </button>
                            </th>
                          </tr>
                        );
                      })}

                    </Row>

                  </div>

            )}


          </Col>
        </Row>
      </Container>
    );
  }
}

export default Profile;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Col sm={12} md={6} lg={4} xl={3}><h1></h1>Something went wrong with this Saved Search. Please contact us to get it resolved.</Col>;
    }

    return this.props.children; 
  }
}
import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Input} from "antd"
import firebase from "firebase";
import {
  Route,
} from "react-router-dom";

class Footer extends Component {
  state = {
    isSignedIn: firebase.auth().currentUser && this.props.isSignedIn,
    isPro: firebase.auth().currentUser && this.props.isPro
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.isPro !== prevProps.isPro) {
      this.setState({ isPro: firebase.auth().currentUser && this.props.isPro });
    }
    if (this.props.isSignedIn !== prevProps.isSignedIn) {
      this.setState({
        isSignedIn: firebase.auth().currentUser && this.props.isSignedIn
      });
    }
  }

  render() {
    const Button2 = () => (
      <Route
        render={({ history }) => (
          <a
            className="footer-item"
            href="#"
            role="button"
            onClick={() => {
              firebase.auth().signOut()
            }}
          >
            Logout
          </a>
        )}
      />
    );

    const date = new Date();
    return (
      <footer className="page-footer font-small bg-light py-3 d-print-none">


        <Container fluid><div align="center">

        {this.props.DistributorInfo &&<div class="we_heart_company-module__weHeart___2zE01">We <svg aria-hidden="true" class="gwb_DkTS1 gwb_3z6Hq we_heart_company-module__animatedHeart___2Z1EQ" role="img" focusable="false" aria-labelledby="bXyXUUCrFXZsxCINgDBZrps" width="1em" fill="currentColor" viewBox="0 0 16 16"><title id="bXyXUUCrFXZsxCINgDBZrps">love</title><path d="M6.929 2.85c-1.584-1.508-3.657-1.445-5.24.064-2.833 2.7-.116 7.53 3.063 9.854 1.518 1.11 2.965 1.84 3.243 1.84.28 0 1.747-.734 3.28-1.848 3.187-2.314 5.86-7.147 3.027-9.846-1.583-1.51-3.647-1.572-5.23-.063-.054.051-1.077 1.069-1.077 1.069s-.992-.998-1.066-1.07z"></path></svg> {this.props.DistributorInfo.name}</div>}

        

         {false&& <Row>
            <Col md={2}>

              <ul className="list-unstyled">
                {this.state.isSignedIn && firebase.auth().currentUser ? (
                  <li>
                    <Button2 />
                  </li>
                ) : null}
                <li><a href="https://instagram.com/somm.pro/">Like Us</a></li>
                {false && <li><a href="https://join.somm.ai/Team">Team</a></li>}
                {false && <li><a href="https://blog.somm.ai/about/">About</a></li>}

              </ul>
            </Col>
            <Col md={2}>
              <ul className="list-unstyled">
                <li><a href="https://clearbit.com">Logos provided by Clearbit</a></li>
                <li><a href="mailto:david@somm.ai">Support</a></li>
                <li><a href="/policies">Policies</a></li>
              </ul>
            </Col>
            <Col md={8}>
              <Row>
                <Col xs={6} sm={3}><div href="https://www.winespectator.com/articles/would-you-like-some-romanee-conti-with-your-fries" target="_blank"><img src="https://beechwoodinn.ws/wp-content/uploads/2019/11/ws-logo.png" className="press-logo-2 launchaco-builder-hoverable-2" /></div></Col>
                <Col xs={6} sm={3}><div href="https://daily.sevenfifty.com/restaurants-and-bars-learn-to-sell-alcohol-to-go/" target="_blank"><img src="https://go.sevenfifty.com/app/uploads/2018/09/SevenFifty-Daily-Logo.jpg" className="press-logo-2 launchaco-builder-hoverable-2" /></div></Col>
                <Col xs={6} sm={3}><div href="https://punchdrink.com/articles/new-wine-shop-on-the-block-popina-restaurant-brooklyn/" target="_blank"><img src="https://images.squarespace-cdn.com/content/v1/5a1cb9fafe54efb296265169/1600361016925-N7INAAWH54EU97U2X7PW/PUNCH+DRINK+logo.png" className="press-logo-2 launchaco-builder-hoverable-2" /></div></Col>
                <Col xs={6} sm={3}><div href="https://www.businessinsider.com/how-wealthy-buy-alcohol-during-pandemic-2020-4" target="_blank"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Business_Insider_Logo.svg/1024px-Business_Insider_Logo.svg.png" className="press-logo-2 launchaco-builder-hoverable-2" /></div></Col>
                {false && <>
                  <Col xs={6} sm={3}><a href="https://menu.somm.ai/r/Atera,%2077%20Worth%20St,%20New%20York" target="_blank"><img src="https://qr.somm.ai/qr1_files/2465fb6c-cdc9-4950-8953-6f9510277ebc.png" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>
                  {false && <Col xs={6} sm={3}><a href="https://menu.somm.ai/r/Del%20Frisco's%20Double%20Eagle%20Steakhouse,%201221%20Ave%20Of%20The%20Americas,%20New%20York" target="_blank"><img src="https://assets.simon.com/tenantlogos/27847.png" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>}
                  <Col xs={6} sm={3}><a href="https://shop.somm.ai/r/Fiola%20Mare,%203100%20K%20St%20NW,%20Washington,%20DC" target="_blank"><img src="https://cdn.launchaco.com/images/3d2d0de1-7e5c-4cc8-9759-12c57664d2b6.png" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>
                  {false && <Col xs={6} sm={3}><a href="https://shop.somm.ai/r/Acquerello,%201722%20Sacramento%20St,%20San%20Francisco" target="_blank"><img src="https://cdn.launchaco.com/images/af22527c-187b-44da-a67e-90d0d3890204.png" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>}

                  {false && <Col xs={6} sm={3}><a href="https://menu.somm.ai/r/Commander's%20Palace,%201403%20Washington%20Ave,%20New%20Orleans" target="_blank"><img src="https://cdn.launchaco.com/images/f61d4aca-af57-4e89-b36a-daec95793c5c.png" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>}
                  {false && <Col xs={6} sm={3}><a href="https://menu.somm.ai/r/Frasca%20Food%20and%20Wine,%201738%20Pearl%20St,%20Boulder" target="_blank"><img src="https://images.getbento.com/accounts/ee2faf19cabb140847c7bca0bb7b4f43/media/images/48240LOGO.png" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>}
                  <Col xs={6} sm={3}><a href="https://menu.somm.ai/r/Angler,%20132%20The%20Embarcadero,%20San%20Francisco" target="_blank"><img src="https://static1.squarespace.com/static/5a82c9a58fd4d2d4199515f5/t/5ab54083758d46d6fb5c1c4c/1586842891749/?format=original" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>
                  <Col xs={6} sm={3}><a href="https://menu.somm.ai/r/Mastro's%20Steakhouse,%201650%20W%20Lp%20S,%20Houston" target="_blank"><img src="https://raster-static.postmates.com/?url=com.postmates.img.prod.s3.amazonaws.com/75a60d36-44ec-4eb6-8b51-dae2437710a1/orig.png&quality=90&w=556&h=556&mode=crop&format=png&v=4" className="press-logo-2 launchaco-builder-hoverable-2" /></a></Col>
                </>}
              </Row>
            </Col>
          </Row>}

         {false&& <div id="mc_embed_signup">
            <form action="https://gmail.us4.list-manage.com/subscribe/post?u=ecd089052ceb28f5d1421da6c&amp;id=48ebae85e3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" noValidate >
              <div id="mc_embed_signup_scroll">
                <Input.Search type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required enterButton={<input style={{backgroundColor:"transparent",borderColor:"transparent"}} type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"  />} />
                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_ecd089052ceb28f5d1421da6c_48ebae85e3" tabindex="-1" value="" /></div>
                
              </div>
            </form>
          </div>}


        </div>


          {/*<div className="col-3 mobile-col-6 mt20"><a href="https://shop.somm.ai/r/Osteria%20Morini,%20218%20Lafayette%20St,%20New%20York" target="_blank"><img src="https://cdn.launchaco.com/images/0319738b-a201-4a90-bb0c-8bf3e747a35a.png" className="press-logo-2 launchaco-builder-hoverable-2"/></a></div>
    <div className="col-3 mobile-col-6 mt20"><a href="https://shop.somm.ai/r/Barndiva,%20231%20Center%20St,%20Healdsburg" target="_blank"><img src="https://cdn.launchaco.com/images/c8e5798b-3f13-468c-901a-3796e3e3da97.png" className="press-logo-2 launchaco-builder-hoverable-2"/></a></div>*/}





          <hr className="clearfix w-100 d-md-none" />
          <div className="col-md-9 mx-auto" />


          <div className="footer-copyright text-center pt-3">
            © {date.getFullYear()} Copyright: Somm Inc. <a href="https://clearbit.com">Logos provided by Clearbit</a>
          </div>


        </Container>
      </footer >
    );
  }
}

export default Footer;

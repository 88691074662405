import { Form, Input, Modal, Select, Button } from "antd";
import firebase from "firebase";
import React from 'react';
import { Component } from 'react';
import { Layout } from 'antd';

const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef();
        this.formRef = React.createRef(); // form ref

        this.state = {
            hasPhoneNumber: false,
        };
    }

    submitForm = () => {
        this.formRef.current.validateFields()
            .then(values => {
                console.log(values); // values from form
                console.log(values.phone); // phone number
                console.log(values.mailingAddress); // address
                // store values to firebase here
                firebase.auth().currentUser.updateProfile({
                    displayName: values.name
                });

                firebase.database().ref(`/users/${firebase.auth().currentUser.uid}/hubspot/`).update({
                    phone: values.phone,
                });

                firebase.database().ref(`/users/${firebase.auth().currentUser.uid}`).update({
                    role: values.type,
                    mailingAddress: values.mailingAddress, // store the address in the database
                });
            });
    }

    closeForm = () => {
        alert("Please enter a phone number!")
    }

    render() {
        return (
            <Layout>
                <Content style={{ margin: "25px" }}>
                    <Form
                        layout="vertical"
                        name="form_in_modal"
                        ref={this.formRef}
                    >
                        Update your information.
                        <br />
                        <br />
                        <br />
                        <Form.Item
                            name="name"
                            label="Name"
                            initialValue={firebase.auth().currentUser.displayName}
                            rules={[
                                { required: true },
                            ]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            initialValue={firebase.auth().currentUser.email}
                        >
                            <Input style={{ width: '100%' }} disabled />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="Mobile Phone Number"
                            initialValue={Object.prototype.toString.call(this.props.hasPhone) === "[object String]" ? this.props.hasPhone : null}
                            rules={[
                                { required: true, message: 'Please input your cellphone number' },
                                { min: 10, message: 'Phone number must be a minimum of 10 characters.' }
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="e.g. 3475140591" />
                        </Form.Item>

                        <Form.Item
                            name="mailingAddress"
                            label="Address (for sending holiday gifts, etc.)"
                            initialValue={Object.prototype.toString.call(this.props.mailingAddress) === "[object String]" ? this.props.mailingAddress : null}
                            rules={[
                                { required: false, message: 'Please input your address' },
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="e.g. 123 Main St, New York, NY 10001" />
                        </Form.Item>

                        <Form.Item name="type" label="Role" initialValue={Object.prototype.toString.call(this.props.role) === "[object String]" ? this.props.role : null}>
                            <Select
                                placeholder="Choose an option"
                                allowClear
                            >
                                <Option value="sales-rep">Sales Rep</Option>
                                <Option value="regional-manager">Regional Manager</Option>
                                <Option value="national-accounts">National Accounts</Option>
                                <Option value="management">Management</Option>
                                <Option value="individual">Individual</Option>
                                <Option value="restaurant">Restaurant/Bar</Option>
                                <Option value="other">Other</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit" onClick={this.submitForm}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Content>
            </Layout>
        );
    }
}

export default Profile;
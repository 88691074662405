import React, { Component } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { Input } from 'antd'
class GoogleMapsAutocomplete extends Component {

    state = {
        showSuggestionsDropdown: true,
        pleaseCorrect: false,
        address: this.props.initialValue,
    };


    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.initialValue) !== JSON.stringify(prevProps.initialValue)) {

            this.setState({ address: this.props.initialValue })
        }
    }

    handleAddressSelect = (address) => {
        this.setState({ address });
        console.log(address)
        geocodeByAddress(address)
            .then(this.props.callback)
            .catch((err) => {
                console.log(err)
                this.setState({ pleaseCorrect: true })
            });

    };
    handleFocusSearchPlaces = () => {
        this.setState({
            showCurrentLocation: true,
            showSuggestionsDropdown: true
        })
    }

    handleBlurSearchPlaces = () => {
        setTimeout(() => {
            this.setState({
                showCurrentLocation: false
            })
        }, 50)
    }
    handleAddressChange = address => {
        console.log(address)
        this.setState({
            address: address,
            showSuggestionsDropdown: true,
            showCurrentLocation: true,
            pleaseCorrect: false
        });
    };

    setCurrentLocation = () => {
        // Set the current location
        if (navigator.geolocation) {
            this.setState({ "settingCurrentLocation": true }, () => {

                navigator.geolocation.getCurrentPosition(position => {
                    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_FIREBASE}`)
                        .then(res => res.json())
                        .then((response) => {
                            const effectiveResult = response.results && response.results[0];
                            if (effectiveResult) {
                                const address = effectiveResult.address_components.find(result => result.types.includes('locality') && result.types.includes('political'));
                                //console.log(effectiveResult)
                                this.setState({
                                    address: effectiveResult.formatted_address, // pre-fill the location input
                                    nearByLocation: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude
                                    },
                                });
                                this.props.setCity(address.short_name?address.short_name:address.long_name);
                                this.props.setnearByLocation(
                                    {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude
                                    }
                                )
                            }
                        })
                        .then(() => {
                            this.setState({ "settingCurrentLocation": false })
                        })
                }
                )

            })
            // Ask for location
        }
    };


    render() {

        if (window.google) {
            return <div>
                {this.state.pleaseCorrect && <>Please enter a city, zip or restaurant. You can also use the state filter shown by the icon <LocationSearchingIcon /></>}

                <PlacesAutocomplete

                    value={this.state.address}
                    onChange={this.handleAddressChange}
                    onSelect={address => { this.handleAddressSelect(address) }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="autocomplete-main">

                            <input
                                {...getInputProps({
                                    placeholder: 'city, zip or restaurant ↵',
                                    className: 'location-search-input',
                                    onFocus: this.handleFocusSearchPlaces,
                                    onBlur: this.handleBlurSearchPlaces
                                })}

                            />
                            <div className="input-icon">
                                <img className="reactive-field__icon" width="20px" src={this.state.settingCurrentLocation ? "https://cdn-3.convertexperiments.com/uf/10025040/10024673/1565687999loader.gif" : "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"} />
                            </div>


                            {(this.state.showSuggestionsDropdown && (this.state.showCurrentLocation || loading || suggestions.length)) ? <div className="autocomplete-dropdown-container">
                                {this.props.geolocate && this.state.showCurrentLocation &&
                                    <div onMouseDown={this.setCurrentLocation} className="suggestion-item">
                                        <span
                                            style={{
                                                color: "#950016"
                                            }}
                                            className="suggestions-label"
                                        //onMouseDown={this.setCurrentLocation}
                                        >
                                            Current Location
                                        </span>
                                    </div>
                                }
                                {loading && (
                                    <div className="suggestion-item">
                                        <span className="suggestions-label">Searching Places...</span>
                                    </div>
                                )}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item-active'
                                        : 'suggestion-item';
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                            })}
                                        >
                                            <span className="suggestions-label">{suggestion.description} 
                                            {this.props.city&&this.props.adder&&<span onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                geocodeByAddress(suggestion.description)
                                                    .then(this.props.adder)


                                            }}>➕</span>}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div> : null
                            }
                        </div>
                    )}
                </PlacesAutocomplete>

            </div>

        } else { return null }
    }
}

export default GoogleMapsAutocomplete;
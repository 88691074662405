import {
  DataSearch,
  ReactiveBase, ReactiveComponent
} from "@appbaseio/reactivesearch";
import { Card, Skeleton } from 'antd';

import React, { Component, Suspense } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import getCountryFlag from "../../js/getCountryFlag";
var Url = require('url-parse');


const loading = <>
  <Card>
    <Skeleton avatar active paragraph={{ rows: 0 }} />
  </Card>
  <Card>
    <Skeleton avatar active paragraph={{ rows: 0 }} />
  </Card>
  <Card>
    <Skeleton avatar active paragraph={{ rows: 0 }} />
  </Card>
</>


class Main extends Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();

    this.state = {
      n: 3,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }



  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //console.log(error, errorInfo);
  }

  defaultGeoQuery = () => this.state.nearByLocation ? {
    query: {
      geo_distance: {
        distance: `${this.props.radius}mi`,
        "yelpCoordinates": `${this.state.nearByLocation.lat}, ${this.state.nearByLocation.lng}`,
      },
    }
  } : {}

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.nearByLocation) !== JSON.stringify(prevProps.nearByLocation)) {
      this.setState({ nearByLocation: this.props.nearByLocation })
    }
  }


  componentDidUpdate(prevProps, prevState) {

    if (prevProps.search != this.props.search) {
      this.props.db
        .collection("groups_01")
        .find({ "name": { "$regex": this.props.search, $options: "i" } }, {
          limit: 3, projection: { "name": 1, "domain": 1, "description": 1 }, sort: { "name": 1 }
        })
        .toArray()
        .then(items => {
          this.props.updateGroups(
            [
              {
                title: "National Accounts",
                languages: items.map(x =>
                ({
                  title: x.name,
                  description: x.description,
                  image: "//logo.clearbit.com/" + x.domain,
                  link: "/nas/" + x._id,
                })
                )
              }
            ]
          )
        }
        )
    }
  }











  nameQuery = function () {

    return {}

  };


  render() {





    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ReactiveBase
          {...this.props.ReactiveBaseProps}
        >

          <DataSearch
            showClear={true}
            showFilter={true}
            componentId="master-w"
            URLParams={true}
            fieldWeights={[1, 3]}
            dataField={["search.ascii"]}
            queryFormat="and"
            placeholder="Text Search"
            autosuggest={false}
            filterLabel="Search"
            fuzziness={2}
            value={this.props.search}
          />

          <ReactiveComponent
            componentId={`wine-comparison-2-awefawef`}
            react={{
              and: [
                "master-w",
              ]
            }}
            defaultQuery={() => (
              {
                "query": { "match_all": {} },
                "size": 0,
                "aggs": {
                  //"varietals": {
                  //  "significant_terms": {
                  //    "field": "wine_id2.xwine.properties.Variety",
                  //    "size": 5,
                  //  }
                  //},
                  //"regions": {
                  //  "significant_terms": {
                  //    "field": "wine_id2.xwine.xregion",
                  //    "size": 5,
                  //  }
                  //},
                }
              }

            )
            }

            onData={
              data => this.props.updateAggs(!data.aggregations ? null : data.aggregations)
            }

            render={({ loading, aggregations }) => null}
          />
          <ReactiveComponent
                  key={this.state.debug}
                  componentId="type-component-awefawef"
                  customQuery={this.props.baseFilter(this.props.settings.likelyType)}
                />
          <ReactiveComponent
            componentId="myColorPickerawegawegawegawegaweg"   // a unique id we will refer to later
            react={{
              and: [
                "master-w",
                "type-component-awefawef",
              ]
            }}
            defaultQuery={() => ({
              aggs: {
                color: {
                  terms: {
                    field: 'wine_id2.xwine.xname',
                    size: this.state.n,
                  },
                  "aggs": {
                    "first": {
                      "top_hits": {
                        "size": 1
                      }
                    },
                  }
                }
              }
            })}


            onData={
              data => this.props.update(
                [
                  {
                    title: this.props.settings.titlePlural,
                    languages: !data.aggregations ? [] : data.aggregations.color.buckets
                    .map(x => [x, x.first.hits.hits[0]._source])
                    .filter(x=>x[1]&&x[1].wine_id2 && x[1].wine_id2.xwine && x[1].wine_id2.xwine.properties)
                    .map(([x, item]) =>
                    ({
                      title: item.wine_id2.xwine.xname,
                      //description: `${item.wine_id2.xwine.properties.Variety}, ${item.wine_id2.xwine.properties.Region}, ${item.wine_id2.xwine.properties.Producer}`,
                      variety: item.wine_id2.xwine.properties.Variety,
                      regions: item.wine_id2.xwine.xregion,
                      image: getCountryFlag(item.wine_id2.xwine.properties.Country),
                      link: '/w/' + encodeURIComponent(`${item.wine_id2.xwine.xname.replace("%", "percent")}`),
                      wine: encodeURIComponent(`${item.wine_id2.xwine.xname.replace("%", "percent")}`),
                      popularity: x.doc_count
                    })
                    )
                  },
                  {
                    title: "Brands",
                    languages: !data.aggregations ? [] : data.aggregations.color.buckets
                      .map(x => x.first.hits.hits[0]._source)
                      .filter(x=>x&&x.wine_id2 && x.wine_id2.xwine && x.wine_id2.xwine.properties)
                      .filter((v, i, a) => a.findIndex(t => (t.wine_id2.xwine.properties.Producer === v.wine_id2.xwine.properties.Producer)) === i)
                      .map((item) =>
                      ({
                        title: item.wine_id2.xwine.properties.Producer,
                        description: null,
                        image: getCountryFlag(item.wine_id2.xwine.properties.Country),
                        link: '/p/' + encodeURIComponent(`${item.wine_id2.xwine.properties.Producer.replace("%", "percent")}`),
                        producer: encodeURIComponent(`${item.wine_id2.xwine.properties.Producer.replace("%", "percent")}`)
                      })
                      )
                  }
                ])
            }



            render={({ aggregations, setQuery }) => null
            }
          />

          <DataSearch
            queryFormat="and"
            componentId="master-r"
            react={{ and: "geo-search" }}
            debounce={200}
            autosuggest={false}
            renderNoSuggestion={() => "No places found"}
            URLParams={true}
            dataField={['rid.ascii']}
            placeholder="Find a restaurant..."
            value={this.props.search}
          />
          <ReactiveComponent
            componentId="myColorPickerawegawegaawefawefawefwegawegaweg"   // a unique id we will refer to later
            react={{
              and: [
                "master-r",
                "type-component-awefawef",
              ]
            }}
            defaultQuery={() => ({
              aggs: {
                color: {
                  terms: {
                    field: "res2.alias",
                    size: 3,
                  },
                  "aggs": {

                    "first": {
                      "top_hits": {
                        "size": 1
                      }
                    },
                  }
                }
              }
            })}


            onData={
              data => this.props.updateRests(
                [
                  {
                    title: "Restaurants",
                    languages: !data.aggregations ? [] : data.aggregations.color.buckets.map(x => [x, x.first.hits.hits[0]._source]).map(([x, item]) =>
                    ({
                      title: item.res.name,
                      description: `📍${item.res.location.city} ${item.res.location.state}, ${item.res.price}, ${item.res.display_phone}, ${item.res.location.address1}, ${item.res.location.zip_code} | ${(new Url(item.scrapeData.domain)).hostname}`,
                      image: `//logo.clearbit.com/${(new Url(item.res.googleWebsite ? item.res.googleWebsite : item.scrapeData.domain)).hostname}`,
                      link: "/r/" + item.res2?.alias,
                      restaurant: item.res2?.alias,
                    })
                    )
                  }
                ])
            }



            render={({ aggregations, setQuery }) => null
            }
          />



        </ReactiveBase>
      </Suspense>
    );
  }
}
export default withRouter(Main);

import React, { Component } from "react";
import firebase from "firebase";
import { Input, Button, Col, Row, Layout, Space, Form } from "antd"
const googleProvider = new firebase.auth.GoogleAuthProvider();

class Login extends Component {
  state = {
    email: window.localStorage.getItem("emailForSignIn") || "",
    password: "",
    isDisabled: false,
    isNewUser: false
  };

  setEmail = (email) => this.setState({ email: email })
  setPassword = (password) => this.setState({ password: password });

  handleEmailSignIn = () => {
    signInWithEmailOnly(this.state.email);
    this.setState({ isDisabled: true });
  };

  handlePasswordSignIn = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  handleCreateAccount = async () => {
    try {
      await signUpWithEmailAndPassword(this.state.email, this.state.password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  toggleNewUser = () => {
    this.setState(prevState => ({ isNewUser: !prevState.isNewUser }));
  };

  componentDidMount() {

    import("firebaseui").then(obj => {
      this.setState({
        firebaseui: obj
        // Configure FirebaseUI.
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {

    const { firebaseui, uiConfig } = this.state;

    if (firebaseui && !uiConfig) {
      this.setState({

        uiConfig: {
          'credentialHelper': firebaseui.auth.CredentialHelper.NONE,

          // Popup signin flow rather than redirect flow.
          signInFlow: "popup",
          // We will display Google and Facebook as auth providers.
          signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            //firebase.auth.FacebookAuthProvider.PROVIDER_ID
          ],
          callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: this.props.redirect || (() => false)
          },

          //signInSuccessUrl: this.props.redirectURL||"/"
        }
      });
    }
  }

  handleForgotPassword = async () => {
    try {
      if (!this.state.email) {
        alert("Please enter your email address");
        return;
      }
      await firebase.auth().sendPasswordResetEmail(this.state.email);
      alert("Password reset email sent! Please check your inbox.");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  isAreasEmail = (email) => {
    return email.toLowerCase().endsWith('@areas.com');
  };

  render() {

    const setEmail = this.setEmail
    const email = this.state.email
    const searchParams = new URLSearchParams(window.location.search);
    const hasParameter = searchParams.has('apiKey');
    const { isDisabled, isNewUser } = this.state;
    const showPasswordOptions = this.isAreasEmail(email);



    return this.state.uiConfig ? <Layout style={{ padding: '0px 0px 0px 24px' }}>

      <Row justify="space-around" align="middle" gutter={[16, 16]}>
        <Col md={8} sm={24} xs={24}>
          <span>
            <img className="navbar-logo" src={"/images/F&B Insights.svg"} alt="" style={{ width: '200px' }} />
          </span>
          <br />
          <br />
          <br />
          {hasParameter ? <>We are trying to log you in. If this screen does
            not change soon, there is a mistake. It likely is because the link you clicked is expired
            or you already used it once. Check your inbox and make sure you are clicking the latest email from us.
            If you are already doing that, try this process again, and make sure you use the new email we sent you, not one of the previous ones.
            Also, make sure that the brower you are using
            is consistent on both sides of this process. <a href="http://help.somm.ai/en/collections/3826862-signing-up-or-logging-in">Here is a video of the process.</a>
            You can also reach out to us for help using the chat in the bottom right.</> : `You no longer need to remember a password to access Somm.ai. You will receive an email to log-in.`}
          <br />
          <br />
          <Space direction="vertical" style={{ width: '100%' }}>
            <Form>
              <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email', message: "Please enter a valid email" }]}>
                <Input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your e-mail"
                  size="large"
                />
              </Form.Item>
              
              {showPasswordOptions && (
                <Form.Item 
                  name={['user', 'password']} 
                  label="Password"
                  rules={[
                    { 
                      required: isNewUser, 
                      message: 'Password must be at least 6 characters long',
                      min: 6 
                    }
                  ]}
                >
                  <Input.Password
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value)}
                    placeholder="Enter your password"
                    size="large"
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Space direction="vertical" style={{ width: '100%' }}>
                  {showPasswordOptions ? (
                    !isNewUser ? (
                      <>
                        <Button
                          onClick={() => this.handlePasswordSignIn()}
                          type="primary"
                          block
                        >
                          Sign in with Password
                        </Button>
                        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
                          <Button
                            onClick={this.toggleNewUser}
                            type="link"
                          >
                            Don't have an account? Create one
                          </Button>
                          <Button
                            onClick={this.handleForgotPassword}
                            type="link"
                          >
                            Forgot Password?
                          </Button>
                        </Space>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => this.handleCreateAccount()}
                          type="primary"
                          block
                        >
                          Create Account
                        </Button>
                        <Button
                          onClick={this.toggleNewUser}
                          type="link"
                          block
                        >
                          Already have an account? Sign in
                        </Button>
                      </>
                    )
                  ) : (
                    <Button
                      onClick={() => this.handleEmailSignIn()}
                      type="primary"
                      block
                      disabled={isDisabled}
                    >
                      Sign in with Email Link
                    </Button>
                  )}
                  
                  {isDisabled && (
                    <Button
                      onClick={() => this.setState({ isDisabled: false })}
                      type="primary"
                      block
                    >
                      I misspelt my email
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>

            {false && <input
              type="password"
              className="login__textBox"
              //value={password}
              //onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />}

            {false && <Button onClick={signInWithGoogle} danger block>
            Sign-up or Login with Google
            </Button>}
          </Space>

          <span>
            <p style={{ marginTop: '50px' }}>powered by</p>
            <img className="navbar-logo" src={"/images/somm.png"} alt="" style={{ width: '100px' }} />
          </span>
        </Col>
        <Col md={16} sm={24} xs={24} style={{ height: "100vh", backgroundColor: "#960000" }}>

        </Col>
      </Row>


    </Layout> : null

  }
}

export default Login


// passwordless signin
const signInWithEmailOnly = async (email) => {
  try {
    await firebase.auth().sendSignInLinkToEmail(email, {
      // Your redirect URL
      url: window.location.href,
      //url: 'http://localhost:3000', 
      handleCodeInApp: true,
    });
    alert("An authentication email has been sent to your email! Please wait until you receive it. If you do not receive it, check your spam folder, and check you spelt your email correctly. If after 15min you still do not receive an email from us, please contact us (bottom left corner).");
    window.localStorage.setItem('emailForSignIn', email);

  } catch (err) {
    console.log(err.message);
  }
}

const signInWithGoogle = async () => {
  try {
    const res = await firebase.auth().signInWithPopup(googleProvider);

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signUpWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
import React, { Component } from 'react';
import { Tag as Badge } from 'antd';
import { filtersProps } from '../main/components/filtersProps';

class SearchTermsBadges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    searchParser = (x, index) => <Badge bordered={false} 
        color="volcano"
        closable={this.props.removeFilter && x && index > 0}
        onClose={() => this.takeout(x)}
    >
        {x == "nas"
            ? "nas"
            : x == "map"
                ? "map"
                : x == "places"
                    ? "places"
                    : x == "s"
                        ? "🔎"
                        : x == "p"
                            ? "👩‍🌾"
                            : x == "w"
                                ? "🍷"
                                : x == "r"
                                    ? "📍"
                                    : x}
    </Badge>

    takeout = (x) => {
        let path = decodeURIComponent(this.props.item.path);

        // Split the path into parts
        const pathParts = path.split('/');
    
        if (pathParts.length > 2) {
            // Split the items by comma
            let items = pathParts[2].split(',');
    
            // Remove the item x from the array
            items = items.filter(item => item !== x);
    
            // Join the remaining items back with commas
            pathParts[2] = items.join(',');
    
            // If all items were removed, change /p/ to /s/
            if (items.length === 0) {
                pathParts[1] = 's';
                pathParts[2] = '';
            }
    
            // Join the path parts back together
            path = pathParts.join('/');
        }
    
        // Append the query string to the path
        const newUrl = path + window.location.href.split(window.location.pathname)[1];
    
        // Update the URL
        this.props.history.push(newUrl);
}


    render() {
        var item = this.props.item
        var itemsN = decodeURIComponent(item.path).split("/").map(y => y.split(",")).flat().length - 1
        return (
            <span>
                {item.city && <Badge bordered={false}  color="red">{item.city}</Badge>}

                {decodeURIComponent(item.path).split("/").filter(y=>y.length>0)
                .map(y => y.split(",").filter(x=>x.length>0)).flat().slice(0, this.state.expanded ? 10000000 : 5).map(this.searchParser)}

                {itemsN > 6 && <a onClick={() => this.setState({ expanded: !this.state.expanded })}>
                    {!this.state.expanded
                        ? <Badge bordered={false}  color="magenta">{itemsN - 5} more...</Badge>
                        : <Badge bordered={false}  color="magenta">less...</Badge>}
                </a>}

                {item.displayName
                    ? Object.entries(item.filters).filter(([key, value])=>key!="dateFilter").map(([key, value]) => <>
                        {JSON.stringify(filtersProps[key]?.revert) != JSON.stringify(value) && <Badge bordered={false} 
                            color=""
                            closable
                            onClose={() => this.props.removeFilter(key)}
                        >
                            {item.displayName[key]}: {JSON.stringify(value)}
                        </Badge>}
                    </>)
                    : Object.entries(item.filters).filter(([key, value])=>key!="dateFilter").map(([key, value]) => <> 
                   { JSON.stringify(Object.values(filtersProps).filter(x=>x.stateName==key)[0]?.revert) != JSON.stringify(value) && <Badge bordered={false}  color="">
                        <div style={{ display: "flex" }}>
                            {key.replace("Filter", "")}: {JSON.stringify(value)}
                        </div>
                    </Badge>}</>)}

            </span>
        )
    }
}

export default SearchTermsBadges;
const googleToSomm = (email, results, db, callback, force) => {
  if (results[0].types.includes("establishment")) {
    db
      .collection("r.01")
      .findOne({ googlePlaceID: results[0].place_id }, { limit: 1 })
      .then(todos => {
        ////console.log(todos)
        if (force) {
          //console.log(results[0])
          if (window.confirm("Would you like to update?")) {
            fetch('https://docs.somm.ai/updateRestaurantFromGoogleId', {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              mode: 'no-cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              //credentials: 'omit', // include, *same-origin, omit
              headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              body: JSON.stringify({...results[0],email:email}) // body data type must match "Content-Type" header
            })
              .then(result => {
                alert(`Adding ${results[0].place_id}.`)
                db
                  .collection("r.01")
                  .findOne({ googlePlaceID: results[0].place_id }, { limit: 1 })
                  .then((r) => {
                    if (r && typeof callback == "function") {
                      callback(r)
                    }
                  })
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }
        } else if (!!todos) {
          //alert('already in database, nothing to do')
          return todos
        } else {
          //console.log(results[0])
          if (window.confirm(`This establishment is not in the database. Would you like to add it?`)) {
            fetch('https://docs.somm.ai/createRestaurantFromGoogleId', {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              mode: 'no-cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              //credentials: 'omit', // include, *same-origin, omit
              headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              body: JSON.stringify({...results[0],email:email}) // body data type must match "Content-Type" header
            })
              .then(result => {
                alert(`Adding ${results[0].name} - ${results[0].place_id}.`)
                db
                  .collection("r.01")
                  .findOne({ googlePlaceID: results[0].place_id }, { limit: 1 })
                  .then((r) => {
                    if (r && typeof callback == "function") {
                      callback(r)
                    }
                  })
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }
        }
      })
      .then((r) => {
        if (r && typeof callback == "function") {
          callback(r)
        }
      })
  }
}

export default googleToSomm
import { getCode } from "country-list";

const getColor = xcountry => xcountry === "USA"
  ? "/images/svg/us.svg"
  : xcountry === "United Kingdom"
    ? "/images/svg/gb.svg"
    : xcountry && getCode(xcountry)
      ? `/images/svg/${getCode(
        xcountry
      ).toLowerCase()}.svg`
      : "/images/somm.png"

export default getColor
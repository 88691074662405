import { Button, List, Layout, Row, Col } from 'antd';
import firebase from "firebase";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';



class SavedReports extends Component {

    state = {
        reports: []
    }


    rename = (key) => {
        const name = prompt("Enter a new name for this report")
        firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}/reports/${key}`)
            .update({
                name: name,
            });
    }

    delete = (key) => {
        firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}/reports/${key}`)
            .remove();
    }

    open = (key) => {
        // write the last open time to firebase
        firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}/reports/${key}`)
            .update({
                lastOpened: firebase.database.ServerValue.TIMESTAMP,
            });

        // go to path based on this.state.reports.key.path
        const path = this.state.reports[key].path
        this.props.history.push(path+'&defaultActiveKey=ReportBuilder')
    }

    copyLinkToClipboard = (key) => {
        const path = this.state.reports[key].path
        navigator.clipboard.writeText(window.location.origin + path +'&defaultActiveKey=ReportBuilder')
    }

    componentDidMount() {
        firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}/reports`)
            .on("value", (snapshot) => {
                this.setState({ reports: snapshot.val() ? snapshot.val() : [] });
            });
    }
    render() {
        return (

                    <List
                    style={{margin:"10px 10px"}}
                        itemLayout="horizontal"
                        dataSource={Object.entries(this.state.reports).sort((a, b) => {
                            let aLastOpened = a[1].lastOpened;
                            let bLastOpened = b[1].lastOpened;

                            if (aLastOpened > bLastOpened) {
                                return -1;
                            } else if (aLastOpened < bLastOpened) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })}
                        renderItem={([key, value], index) => (
                            <List.Item
                                actions={[
                                    <Button danger onClick={() => this.delete(key)}>Delete</Button>,
                                    <Button onClick={() => this.rename(key)}>Rename</Button>,
                                    <Button onClick={() => this.copyLinkToClipboard(key)}>Copy Link</Button>,
                                    <Button type="primary" onClick={() => this.open(key)}>Open</Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={<>{value.name ? value.name : new Date(value.createTime).toLocaleString()}</>}
                                    description={`Created ${new Date(value.createTime).toLocaleString()} | Last Opened ${value.lastOpened ? (new Date(value.lastOpened).toLocaleString()) : "Never"}`}

                                />
                            </List.Item>
                        )}
                    />
        );
    }
}

export default withRouter(SavedReports);